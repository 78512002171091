import { Link, useRouter } from '@tanstack/react-router';
import { Feature } from 'flagged';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/shallow';
import { useFullScreen } from '../../hooks/use-fullscreen';
import { logout, useIsLoggedIn } from '../AuthProvider';
import { FullscreenIcon } from '../Icons';
import { CloseIcon } from '../Icons/CloseIcon';
import { SettingsIcon } from '../Icons/SettingsIcon';
import { ColorSettings } from './ColorSettings';
import { useToolStore } from './tool.store';

export function Options(props: {}) {
    const router = useRouter();

    const toolStore = useToolStore(
        useShallow((state) => ({
            shouldShowScatterPaths: state.shouldShowScatterPaths,
            showScatterPaths: state.showScatterPaths,
            hideScatterPaths: state.hideScatterPaths,
            shouldShowLabelsSelected: state.shouldShowLabelsSelected,
            showLabelsSelected: state.showLabelsSelected,
            hideLabelsSelected: state.hideLabelsSelected,
            labelSize: state.labelSize,
            setLabelSize: state.setLabelSize,
            circleSize: state.circleSize,
            setCircleSize: state.setCircleSize,
            notSelectedOpacity: state.notSelectedOpacity,
            setNotSelectedOpacity: state.setNotSelectedOpacity,
            selectedFeatures: state.selectedFeatures,
        }))
    );

    const [showSettingsPanel, setShowSettingsPanel] = useState(false);
    const fullscreenHandle = useFullScreen();
    const { isLoggedIn, recheckAuth } = useIsLoggedIn();

    const { t } = useTranslation();

    const hasSelectedFeatures = toolStore.selectedFeatures.length > 0;

    return (
        <>
            <div className="flex gap-4 items-center">
                {hasSelectedFeatures && (
                    <label
                        className={`flex flex-row gap-2 text-sm items-center`}
                    >
                        <span>{t('opacity-bubbles')}</span>
                        <input
                            className="w-24"
                            type="range"
                            min="0"
                            max="0.6"
                            step="0.01"
                            value={toolStore.notSelectedOpacity.features}
                            onChange={(e) => {
                                toolStore.setNotSelectedOpacity({
                                    key: 'features',
                                    value: +e.target.value,
                                });
                            }}
                        />
                    </label>
                )}
                <label className="flex flex-row gap-2 cursor-pointer items-center text-sm">
                    <input
                        type="checkbox"
                        checked={toolStore.shouldShowScatterPaths}
                        onChange={() =>
                            toolStore.shouldShowScatterPaths
                                ? toolStore.hideScatterPaths()
                                : toolStore.showScatterPaths()
                        }
                        className="checkbox checkbox-primary checkbox-xs"
                    />
                    {t('Linjer')}
                </label>

                <div className="flex gap-2">
                    <label className="btn btn-sm btn-circle btn-primary swap swap-rotate flex">
                        <input
                            checked={showSettingsPanel}
                            onChange={() =>
                                setShowSettingsPanel((value) => !value)
                            }
                            type="checkbox"
                        />
                        <div className="absolute swap-off">
                            <div className="indicator">
                                <Feature name="USER_LOGIN">
                                    {isLoggedIn && (
                                        <span className="indicator-item badge badge-warning"></span>
                                    )}
                                </Feature>
                                <SettingsIcon className="fill-white" />
                            </div>
                        </div>
                        <div className="swap-on absolute">
                            <CloseIcon fill="white" />
                        </div>
                    </label>
                    <button
                        type="button"
                        className="btn btn-sm btn-circle btn-primary"
                        onClick={() => {
                            if (fullscreenHandle.active) {
                                fullscreenHandle.exit();
                            } else {
                                fullscreenHandle.enter();
                            }
                        }}
                    >
                        <FullscreenIcon />
                    </button>
                </div>
            </div>
            {showSettingsPanel && (
                <div className="bg-base-100 rounded-lg p-3 shadow-xl flex flex-col gap-3 text-sm absolute bottom-16 right-2">
                    <Feature name="USER_LOGIN">
                        {isLoggedIn && (
                            <div className="self-end text-warning">
                                <span>Du är inloggad</span>
                            </div>
                        )}
                    </Feature>

                    <div className="flex">
                        <h2 className="flex-1 text-lg">{t('settings')}</h2>
                        <Feature name="USER_LOGIN">
                            <div>
                                {isLoggedIn ? (
                                    <button
                                        type="button"
                                        className="btn btn-xs btn-ghost uppercase"
                                        onClick={() =>
                                            logout().then(() => {
                                                recheckAuth();
                                            })
                                        }
                                    >
                                        Logga ut
                                    </button>
                                ) : (
                                    <Link to="/login">
                                        <button
                                            type="button"
                                            className="btn btn-xs btn-ghost uppercase"
                                        >
                                            Logga in
                                        </button>
                                    </Link>
                                )}
                            </div>
                        </Feature>
                    </div>
                    <div className="flex flex-col gap-6">
                        <div className="flex flex-col gap-1">
                            <label className="flex flex-row gap-2 cursor-pointer items-center">
                                <input
                                    type="checkbox"
                                    checked={toolStore.shouldShowLabelsSelected}
                                    onChange={() =>
                                        toolStore.shouldShowLabelsSelected
                                            ? toolStore.hideLabelsSelected()
                                            : toolStore.showLabelsSelected()
                                    }
                                    className="checkbox checkbox-primary checkbox-xs"
                                />
                                {t('etikett-markerade')}
                            </label>
                            <label className={`flex flex-col max-w-xs`}>
                                {t('size-labels')}
                                <label className="flex items-center gap-1">
                                    <input
                                        className=""
                                        type="range"
                                        min="8"
                                        max="35"
                                        step="1"
                                        value={toolStore.labelSize}
                                        onChange={(e) => {
                                            toolStore.setLabelSize(
                                                +e.target.value
                                            );
                                        }}
                                    />
                                </label>
                            </label>
                        </div>

                        <label className={`flex flex-col max-w-xs`}>
                            {t('not-selected')}
                            <label className={`flex flex-col max-w-xs`}>
                                <span
                                    className={`${
                                        !hasSelectedFeatures
                                            ? 'text-gray-400'
                                            : undefined
                                    }`}
                                >
                                    {t('opacity-bubbles')}
                                </span>
                                <input
                                    disabled={!hasSelectedFeatures}
                                    type="range"
                                    min="0"
                                    max="0.6"
                                    step="0.01"
                                    value={
                                        toolStore.notSelectedOpacity.features
                                    }
                                    onChange={(e) => {
                                        toolStore.setNotSelectedOpacity({
                                            key: 'features',
                                            value: +e.target.value,
                                        });
                                    }}
                                />
                            </label>
                            <label className={`flex flex-col max-w-xs`}>
                                <span
                                    className={`${
                                        !hasSelectedFeatures ||
                                        !toolStore.showScatterPaths
                                            ? 'text-gray-400'
                                            : undefined
                                    }`}
                                >
                                    {t('opacity-lines')}
                                </span>
                                <input
                                    disabled={
                                        !hasSelectedFeatures ||
                                        !toolStore.showScatterPaths
                                    }
                                    type="range"
                                    min="0"
                                    max="0.6"
                                    step="0.01"
                                    value={toolStore.notSelectedOpacity.lines}
                                    onChange={(e) => {
                                        toolStore.setNotSelectedOpacity({
                                            key: 'lines',
                                            value: +e.target.value,
                                        });
                                    }}
                                />
                            </label>
                        </label>

                        <label className={`flex flex-col max-w-xs`}>
                            {t('bubble-size')}
                            <label className="flex items-center gap-1">
                                Min
                                <input
                                    className=""
                                    type="range"
                                    min="1"
                                    max="50"
                                    step="1"
                                    value={toolStore.circleSize.min}
                                    onChange={(e) => {
                                        toolStore.setCircleSize({
                                            key: 'min',
                                            value: +e.target.value,
                                        });
                                    }}
                                />
                            </label>
                            <label className="flex items-center gap-1">
                                Max
                                <input
                                    className=""
                                    type="range"
                                    min="1"
                                    max="50"
                                    step="1"
                                    value={toolStore.circleSize.max}
                                    onChange={(e) => {
                                        toolStore.setCircleSize({
                                            key: 'max',
                                            value: Math.max(
                                                +e.target.value,
                                                toolStore.circleSize.min
                                            ),
                                        });
                                    }}
                                />
                            </label>
                        </label>
                        <ColorSettings />
                    </div>
                </div>
            )}
        </>
    );
}
