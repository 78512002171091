import { useQuery } from '@tanstack/react-query';
import { createContext, ReactNode, useContext } from 'react';

async function checkAuth() {
    const response = await fetch(
        `${import.meta.env.VITE_API_URL}/admin/login/check`,
        {
            method: 'GET',
            credentials: 'include',
        }
    );

    if (!response.ok) {
        return {
            isLoggedIn: false,
            roles: [],
        };
    }

    const data = await response.json();

    return {
        isLoggedIn: response.ok,
        roles: data.roles,
    };
}

interface AuthContextType {
    isLoggedIn?: boolean;
    roles?: string[];
    recheckAuth: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
    children: ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {
    const { data: authStatus, refetch } = useQuery({
        queryKey: ['authStatus'],
        queryFn: checkAuth,
    });

    const recheckAuth = async () => {
        refetch();
    };

    return (
        <AuthContext.Provider
            value={{
                isLoggedIn: authStatus?.isLoggedIn,
                roles: authStatus?.roles,
                recheckAuth,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export function useIsLoggedIn() {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useIsLoggedIn must be used within an AuthProvider');
    }

    return {
        isLoggedIn: context.isLoggedIn,
        roles: context.roles,
        recheckAuth: context.recheckAuth,
    };
}

export async function logout() {
    const url = `${import.meta.env.VITE_API_URL}/admin/logout`;
    await fetch(url, {
        method: 'POST',
        credentials: 'include',
    });
}
