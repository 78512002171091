import { FileSelectSmall } from '../FileViewer/FileSelectSmall';
import { FilterFeatures } from '../FilterFeatures';
import { GeoLevelPicker } from '../GeoLevelPicker';
import { BookIcon } from '../Icons/BookIcon';
import { Feature } from 'flagged';
import { DataPackageSelect } from './DataPackageSelect';
import { VarSelect } from '../VarSelect';
import { LanguageSwitch } from '../LanguageSwitch';
import { useFullScreen } from '../../hooks/use-fullscreen';
import { useState } from 'react';
import { ApiConfig, DataOptions, DataPackage, Indicator } from '../../types';
import { DATA_PACKAGES, DataPackageMap, FEATURES } from '../../constants';
import { PackageIcon } from '../Icons/PackageIcon';
import { addSubLabel } from '../FileViewer/addSubLabel';
import { useTranslation } from 'react-i18next';
import { File } from '../FileViewer';
import { useIndicatorFiles } from '../../hooks/use-indicator-files';
import { XAxisIcon, YAxisIcon, SizeIcon, ColorIcon } from '../Icons';
import type { Feature as GeoJSONFeature } from 'geojson';
import { Data } from '../../hooks/use-data.hook';
import { Link, getRouteApi } from '@tanstack/react-router';
import { useHandleResize } from '../../hooks/use-handle-resize';
import { ChrevronLeft } from '../Icons/ChevronLeft';
import { useShallow } from 'zustand/shallow';
import { useToolStore } from './tool.store';
import { FileSelect2 } from '../FileViewer/FileSelect2';

const routeApi = getRouteApi('/');

export function Menu(props: {
    timeRange: [number, number];
    config: ApiConfig;
    selectedDataPackage: DataPackage | null;
    dataPackages: DataPackage[];
    indicators: Indicator[];
    onSetSelectedDatapackage: (dataPackage: DataPackage) => void;
    dataOptions: DataOptions;
    points: GeoJSONFeature[];
    onClearSelectedFeatures: () => void;
    data: Data;
}) {
    const toolStore = useToolStore(
        useShallow((state) => ({
            timeRangeMap: state.timeRangeMap,
            indicatorsMap: state.indicatorsMap,
            setGeoLevel: state.setGeoLevel,
            setFeatureFilter: state.setFeatureFilter,
            featureFilter: state.featureFilter,
            shouldZoomToFiltered: state.shouldZoomToFiltered,
            geoLevel: state.geoLevel,
            toggleShouldZoomToFiltered: state.toggleShouldZoomToFiltered,
            setIndicatorById: state.setIndicatorById,
            getDataOptions: state.getDataOptions,
        }))
    );

    const fullscreenHandle = useFullScreen();
    const [showMenu, setShowMenu] = useState(false);
    const { t, i18n } = useTranslation();

    const indicatorFilesPublic = useIndicatorFiles(
        props.indicators,
        (i) =>
            i.permissionLevel === undefined ||
            i.permissionLevel.includes('public')
    );
    const indicatorFilesHidden = useIndicatorFiles(
        props.indicators,
        (i) =>
            i.permissionLevel !== undefined &&
            i.permissionLevel.includes('user')
    );

    const dataPackageMap = props.dataPackages.reduce((obj, curr) => {
        obj[curr.id] = curr;
        return obj;
    }, {} as DataPackageMap);

    useHandleResize(() => {
        setShowMenu(false);
    });

    const publicFilesWithSubLabel = indicatorFilesPublic.map((f) =>
        addSubLabel({
            file: { ...f, meta: 'public' },
            geoLevel: toolStore.geoLevel,
            timeRangeMap: toolStore.timeRangeMap,
            t,
            timeRange: props.timeRange,
        })
    );
    const hiddenFilesWithSubLabel = indicatorFilesHidden.map((f) =>
        addSubLabel({
            file: { ...f, meta: 'hidden' },
            geoLevel: toolStore.geoLevel,
            timeRangeMap: toolStore.timeRangeMap,
            t,
            timeRange: props.timeRange,
        })
    );

    let filesWithSubLabel = publicFilesWithSubLabel;
    if (hiddenFilesWithSubLabel.length > 0) {
        filesWithSubLabel.push(
            { type: 'divider', title: 'Dolda indikatorer' } // TODO: i18n
        );
        filesWithSubLabel = [...filesWithSubLabel, ...hiddenFilesWithSubLabel];
    }

    function handleChangeFilter(newFilter: string[]) {
        toolStore.setFeatureFilter(newFilter);
    }

    function handleToggleZoomToFiltered() {
        toolStore.toggleShouldZoomToFiltered();
    }

    const dataOptions = toolStore.getDataOptions();

    return (
        <div
            className={`w-12 ${
                !fullscreenHandle.active && 'xl:w-auto'
            } relative`}
        >
            <div
                style={{
                    transform: showMenu ? 'translate(87%, 0)' : undefined,
                }}
                className={`
                absolute
                transition-transform
                right-full
                -mr-12
                isolate
                z-10
                bg-base-200
                p-2
                shadow-xl
                rounded-lg
                max-w-sm
                w-96
                h-full
                ${
                    !fullscreenHandle.active
                        ? `
                    xl:static
                    xl:right-0
                    xl:mr-0
                    xl:bg-transparent
                    xl:p-0
                    xl:shadow-none
                    `
                        : undefined
                }
            `}
            >
                <MenuExpandButton
                    expanded={showMenu}
                    hidden={fullscreenHandle.active}
                    onClick={() => setShowMenu((value) => !value)}
                />

                {/* Left menu small */}
                <div
                    className={`
                    absolute
                    right-2
                    w-12
                    flex 
                    flex-col
                    h-full
                    gap-3
                    ${!showMenu ? '' : 'hidden'}
                    ${
                        !fullscreenHandle.active
                            ? `
                    xl:hidden
                    `
                            : 'justify-center xl:justify-start'
                    }
                `}
                >
                    <div className="gap-2 w-full rounded-lg bg-base-100 self-start flex flex-col items-center p-2">
                        {/* Data package select */}
                        <FileSelectSmall
                            className="btn-primary"
                            files={(
                                props.config?.dataPackages ?? DATA_PACKAGES
                            ).map((d): File => {
                                return {
                                    id: d.id,
                                    name: d.name[i18n.language] ?? d.name['sv'],
                                    type: 'file',
                                    meta:
                                        d.description?.[i18n.language] ??
                                        d.description?.['sv'],
                                };
                            })}
                            value={props.selectedDataPackage?.id ?? ''}
                            label={t('valj-datapaket')}
                            labelIcon={
                                <PackageIcon size={24} className="fill-white" />
                            }
                            onChange={(value) => {
                                const targetDataPackage = dataPackageMap[value];
                                props.onSetSelectedDatapackage(
                                    targetDataPackage
                                );
                            }}
                        />
                        {/* Y axis select */}
                        {FEATURES.FILE_SELECT_2 ? (
                            <FileSelect2
                                tiny
                                indicators={Object.values(
                                    toolStore.indicatorsMap
                                )}
                                value={dataOptions.indicators.yVar.id}
                                label={t('valj-y')}
                                labelIcon={<YAxisIcon size={24} fill="white" />}
                                indicatorsMap={toolStore.indicatorsMap}
                                onChange={(value) => {
                                    toolStore.setIndicatorById({
                                        key: 'y',
                                        indicatorId: value,
                                    });
                                }}
                                tooltip={t('valj-y')}
                            />
                        ) : (
                            <FileSelectSmall
                                files={filesWithSubLabel}
                                value={props.dataOptions.indicators.yVar.id}
                                label={t('valj-y')}
                                labelIcon={<YAxisIcon size={24} fill="white" />}
                                onChange={(value) => {
                                    toolStore.setIndicatorById({
                                        key: 'y',
                                        indicatorId: value,
                                    });
                                }}
                            />
                        )}

                        {/* X axis select */}
                        {FEATURES.FILE_SELECT_2 ? (
                            <FileSelect2
                                tiny
                                indicators={Object.values(
                                    toolStore.indicatorsMap
                                )}
                                value={props.dataOptions.indicators.xVar.id}
                                label={t('valj-x')}
                                labelIcon={<XAxisIcon size={24} fill="white" />}
                                indicatorsMap={toolStore.indicatorsMap}
                                onChange={(value) => {
                                    toolStore.setIndicatorById({
                                        key: 'x',
                                        indicatorId: value,
                                    });
                                }}
                                tooltip={t('valj-x')}
                            />
                        ) : (
                            <FileSelectSmall
                                files={filesWithSubLabel}
                                value={props.dataOptions.indicators.xVar.id}
                                label={t('valj-x')}
                                labelIcon={<XAxisIcon size={24} fill="white" />}
                                onChange={(value) => {
                                    toolStore.setIndicatorById({
                                        key: 'x',
                                        indicatorId: value,
                                    });
                                }}
                            />
                        )}

                        {/* R axis select */}
                        {FEATURES.FILE_SELECT_2 ? (
                            <FileSelect2
                                tiny
                                indicators={Object.values(
                                    toolStore.indicatorsMap
                                )}
                                value={props.dataOptions.indicators.rVar.id}
                                label={t('valj-r')}
                                labelIcon={
                                    <SizeIcon size={24} stroke="white" />
                                }
                                indicatorsMap={toolStore.indicatorsMap}
                                onChange={(value) => {
                                    toolStore.setIndicatorById({
                                        key: 'r',
                                        indicatorId: value,
                                    });
                                }}
                                tooltip={t('valj-r')}
                            />
                        ) : (
                            <FileSelectSmall
                                files={filesWithSubLabel}
                                value={props.dataOptions.indicators.rVar.id}
                                label={t('valj-r')}
                                labelIcon={
                                    <SizeIcon size={24} stroke="white" />
                                }
                                onChange={(value) => {
                                    toolStore.setIndicatorById({
                                        key: 'r',
                                        indicatorId: value,
                                    });
                                }}
                            />
                        )}

                        {/* C axis select */}
                        {FEATURES.FILE_SELECT_2 ? (
                            <FileSelect2
                                tiny
                                indicators={Object.values(
                                    toolStore.indicatorsMap
                                )}
                                value={props.dataOptions.indicators.cVar?.id ?? 'kommun'}
                                label={t('valj-c')}
                                labelIcon={
                                    <ColorIcon
                                        size={24}
                                        className="drop-shadow-lg"
                                    />
                                }
                                indicatorsMap={toolStore.indicatorsMap}
                                onChange={(value) => {
                                    toolStore.setIndicatorById({
                                        key: 'c',
                                        indicatorId: value,
                                    });
                                }}
                                tooltip={t('valj-c')}
                            />
                        ) : (
                            <FileSelectSmall
                                label={t('valj-c')}
                                labelIcon={
                                    <ColorIcon
                                        size={24}
                                        className="drop-shadow-lg"
                                    />
                                }
                                files={[
                                    {
                                        name: 'Kommun',
                                        type: 'file',
                                        id: 'kommun',
                                    },
                                    ...filesWithSubLabel,
                                ]}
                                value={
                                    props.dataOptions.indicators.cVar
                                        ? props.dataOptions.indicators.cVar.id
                                        : 'kommun'
                                }
                                onChange={(value) => {
                                    toolStore.setIndicatorById({
                                        key: 'c',
                                        indicatorId:
                                            value === 'kommun' ? null : value,
                                    });
                                }}
                            />
                        )}
                    </div>

                    <div className="gap-2 w-full rounded-lg bg-base-100 self-start flex flex-col items-center p-2">
                        <GeoLevelPicker
                            vertical
                            dataOptions={props.dataOptions}
                            timeRange={toolStore.timeRangeMap}
                            geoLevels={props.config.geoLevels}
                            geoLevel={toolStore.geoLevel}
                            onSetGeoLevel={toolStore.setGeoLevel}
                        />
                    </div>

                    <div className="gap-2 w-full rounded-lg bg-base-100 self-start flex flex-col items-center p-2">
                        <FilterFeatures
                            tiny
                            points={props.points}
                            featureFilter={toolStore.featureFilter}
                            onToggleZoomToFiltered={handleToggleZoomToFiltered}
                            onChangeFilter={handleChangeFilter}
                            zoomToFiltered={toolStore.shouldZoomToFiltered}
                            showZoomToggle
                        />
                    </div>

                    <div className="gap-2 w-full rounded-lg bg-base-100 self-start flex flex-col items-center p-2">
                        <div
                            className="tooltip tooltip-right"
                            data-tip={t('om-verktyget')}
                        >
                            <Link to="/om">
                                <button
                                    type="button"
                                    className="btn btn-sm btn-secondary  grid place-items-center w-full aspect-square"
                                >
                                    <BookIcon
                                        size={24}
                                        className="fill-white"
                                    />
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>

                {/* Left menu full */}
                <div
                    className={`
                       flex
                       flex-col
                       gap-3
                        max-h-full
                        ${!showMenu && 'hidden'}
                        ${!fullscreenHandle.active && 'xl:flex'}
                   `}
                >
                    <div className="bg-base-100 rounded-lg p-3 flex flex-col relative gap-3 overflow-y-scroll overflow-x-hidden h-full">
                        <Feature name="SHOW_DATA_PACKAGES">
                            <DataPackageSelect
                                selectedDataPackage={props.selectedDataPackage}
                                setSelectedDatapackage={
                                    props.onSetSelectedDatapackage
                                }
                                dataPackageMap={dataPackageMap}
                                dataPackages={props.dataPackages}
                            />
                        </Feature>
                        <div className="z-50 pb-6">
                            <div className="mb-3 flex flex-row items-center gap-2">
                                <h2 className=" text-lg">{t('indikatorer')}</h2>
                            </div>
                            <VarSelect
                                indicatorsMap={toolStore.indicatorsMap}
                                indicatorFiles={filesWithSubLabel}
                                data={props.data}
                                featureFilter={toolStore.featureFilter}
                                geoLevel={toolStore.geoLevel}
                            />
                        </div>
                    </div>
                    <div className="flex flex-row justify-center gap-2 items-center">
                        <GeoLevelPicker
                            geoLevels={props.config.geoLevels}
                            dataOptions={props.dataOptions}
                            timeRange={toolStore.timeRangeMap}
                            geoLevel={toolStore.geoLevel}
                            onSetGeoLevel={toolStore.setGeoLevel}
                        />
                    </div>
                    <div className="bg-base-100 rounded-lg p-3 flex flex-col gap-3">
                        <h2 className="capitalize">{t('filter')}</h2>
                        <FilterFeatures
                            points={props.points}
                            featureFilter={toolStore.featureFilter}
                            zoomToFiltered={toolStore.shouldZoomToFiltered}
                            onToggleZoomToFiltered={handleToggleZoomToFiltered}
                            onChangeFilter={handleChangeFilter}
                            showZoomToggle
                        />
                    </div>

                    <div className="flex justify-center items-center gap-3">
                        <Link to="/om">
                            <button
                                type="button"
                                className="btn btn-secondary flex gap-2 uppercase"
                            >
                                <BookIcon className="fill-secondary-content" />
                                {t('om-verktyget')}
                            </button>
                        </Link>
                        <LanguageSwitch />
                    </div>
                </div>
            </div>
        </div>
    );
}

function MenuExpandButton(props: {
    expanded: boolean;
    hidden: boolean;
    onClick: () => void;
}) {
    return (
        <div
            className={`
                    absolute
                    left-full
                    top-16
                    -ml-2
                    mt-5
                    ${!props.expanded && 'rotate-180'}
                    ${!props.hidden && 'xl:hidden'}
                `}
        >
            <button
                onClick={props.onClick}
                type="button"
                className="btn btn-sm w-6 z-50 border-none bg-base-200 btn-secondary"
            >
                <div>
                    <ChrevronLeft fill="#e9498c" />
                </div>
            </button>
        </div>
    );
}
