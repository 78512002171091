import type { StateCreator } from 'zustand';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import qs from 'qs';

import { DEFAULT_COLOR_SCHEME } from '../../constants';
import type { ColorType, ScaleType } from '../../hooks/use-colorscale';
import type {
    ApiConfig,
    CurrentView,
    DataOptions,
    GeoLevel,
    Indicator,
    IndicatorIds,
    MapType,
    TimeRangeMap,
} from '../../types';
import { type SearchOptional } from '../../zod';
import type { TooltipFeatures } from '../Viz/Tooltip';

type IndicatorsMap = Record<string, Indicator>;

// Core slice for initialization and basic state
type CoreSlice = {
    isInitialized: boolean;
    indicatorsMap: Record<string, Indicator>;
    timeRangeMap: TimeRangeMap;
    initialize: (options: {
        indicators: Indicator[];
        config: ApiConfig;
        search: SearchOptional;
    }) => void;
    clearIsInitialized: () => void;
};

const createCoreSlice: StateCreator<ToolStore, [], [], CoreSlice> = (
    set,
    get
) => ({
    isInitialized: false,
    indicatorsMap: {},
    timeRangeMap: {},
    initialize: (options) => {
        const { indicators, config, search } = options;
        const currentState = get();
        const indicatorsMap = indicators.reduce((obj, curr) => {
            obj[curr.id] = curr;
            return obj;
        }, {} as IndicatorsMap);

        const timeRangeMap = indicators.reduce((obj, curr) => {
            if (!curr.timeRanges) {
                console.error(
                    `Timerange not found in indicator ${JSON.stringify(curr)}`
                );
                return obj;
            }
            return { ...obj, [curr.id]: curr.timeRanges };
        }, {} as TimeRangeMap);

        const newState: Partial<ToolStore> = {};

        if (!currentState.indicators) {
            newState.indicators = {
                x: config.defaultDataPackage.x,
                y: config.defaultDataPackage.y,
                r: config.defaultDataPackage.r,
                c: config.defaultDataPackage.c,
            };
        }

        set({
            isInitialized: true,
            indicatorsMap,
            timeRangeMap,
            ...newState,
            ...search,
        });
    },
    clearIsInitialized: () => {
        set({
            isInitialized: false,
            indicators: null,
        });
    },
});

// Interaction slice for hover and tooltip state
type InteractionSlice = {
    activeFeatureId: string | null;
    setActiveFeatureId: (id: string | null) => void;
    tooltipFeatures: TooltipFeatures | null;
    setTooltipFeatures: (features: TooltipFeatures | null) => void;
    selectedFeatures: string[];
    selectFeature: (id: string) => void;
    deselectFeature: (id: string) => void;
    clearSelectedFeatures: () => void;
    toggleFeatureSelection: (id: string) => void;
    setSelectedFeatures: (features: string[]) => void;
    animationBuster: number;
};

const createInteractionSlice: StateCreator<
    ToolStore,
    [],
    [],
    InteractionSlice
> = (set, get) => ({
    activeFeatureId: null,
    tooltipFeatures: null,
    selectedFeatures: [],
    animationBuster: Date.now(),

    setActiveFeatureId: (id) => {
        set({ activeFeatureId: id });
        if (!id) {
            set({ tooltipFeatures: null });
        }
    },
    setTooltipFeatures: (features) => {
        set({ tooltipFeatures: features });
    },
    selectFeature: (id) => {
        if (get().selectedFeatures.includes(id)) return;
        set({ selectedFeatures: [...get().selectedFeatures, id] });
    },
    deselectFeature: (id) => {
        set({
            selectedFeatures: get().selectedFeatures.filter((f) => f !== id),
        });
    },
    toggleFeatureSelection: (id) => {
        if (get().selectedFeatures.includes(id)) {
            get().deselectFeature(id);
        } else {
            get().selectFeature(id);
        }
    },
    clearSelectedFeatures: () => {
        set({ selectedFeatures: [] });
    },
    setSelectedFeatures: (features) => {
        set({ selectedFeatures: features });
    },
});

// Data slice for options management
type DataSlice = {
    geoLevel: GeoLevel;
    setGeoLevel: (geoLevel: GeoLevel) => void;
    indicators: IndicatorIds | null;
    setVar: (
        options:
            | { indicator: Indicator; key: 'xVar' | 'yVar' | 'rVar' }
            | { key: 'cVar'; indicator: Indicator | null }
    ) => void;
    setIndicatorById: (
        options:
            | { indicatorId: string; key: 'x' | 'y' | 'r' }
            | { key: 'c'; indicatorId: string | null }
    ) => void;
    featureFilter: string[];
    setFeatureFilter: (features: string[]) => void;
    getDataOptions: () => DataOptions;
    setIndicators: (ids: IndicatorIds) => void;
};

const createDataSlice: StateCreator<ToolStore, [], [], DataSlice> = (
    set,
    get
) => ({
    featureFilter: ['Huddinge'],
    indicators: null,
    geoLevel: 'regso',
    setVar: (options) => {
        const { indicators } = get();

        if (!indicators) {
            throw new Error('Indicators was never set during initialize');
        }

        set({
            indicators: {
                ...indicators,
                [options.key]: options.indicator,
            },
        });
    },
    setIndicatorById: (options) => {
        const { indicators } = get();
        if (!indicators) {
            throw new Error('Indicators was never set during initialize');
        }

        // TODO: Check against indicatorsmap that requested indicator exists.

        set({
            indicators: {
                ...indicators,
                [options.key]: options.indicatorId,
            },
        });
    },
    setIndicators: (newIndicators) => {
        set({
            indicators: newIndicators,
        });
    },
    setGeoLevel: (geoLevel) => {
        set({
            geoLevel,
        });
    },
    setFeatureFilter: (newFilter) => {
        set({ featureFilter: newFilter, animationBuster: Date.now() });
    },
    getDataOptions: () => {
        const { geoLevel, indicators, indicatorsMap } = get();
        return {
            geoLevel,
            indicators: {
                xVar: indicatorsMap[indicators!.x],
                yVar: indicatorsMap[indicators!.y],
                rVar: indicatorsMap[indicators!.r],
                cVar: indicators!.c ? indicatorsMap[indicators!.c] : null,
            },
        };
    },
});

// Visualization slice for display settings
type VisualizationSlice = {
    shouldShowScatterPaths: boolean;
    showScatterPaths: () => void;
    hideScatterPaths: () => void;
    shouldShowLabelsSelected: boolean;
    showLabelsSelected: () => void;
    hideLabelsSelected: () => void;
    notSelectedOpacity: { features: number; lines: number };
    setNotSelectedOpacity: (opacity: {
        key: 'features' | 'lines';
        value: number;
    }) => void;
    labelSize: number;
    setLabelSize: (size: number) => void;
    circleSize: { min: number; max: number };
    setCircleSize: (size: { key: 'min' | 'max'; value: number }) => void;
    shouldZoomToFiltered: boolean;
    setShouldZoomToFiltered: (shouldZoomToFiltered: boolean) => void;
    toggleShouldZoomToFiltered: () => void;
    scaleType: ScaleType;
    setScaleType: (scaleType: ScaleType) => void;
    colorType: ColorType;
    setColorType: (colorType: ColorType) => void;
    colorScheme: string;
    setColorScheme: (colorScheme: string) => void;
    mapType: MapType;
    setMapType: (newType: MapType) => void;
    view: CurrentView;
    setView: (newView: CurrentView) => void;
};

const createVisualizationSlice: StateCreator<
    ToolStore,
    [],
    [],
    VisualizationSlice
> = (set, get) => ({
    circleSize: { min: 2, max: 15 },
    colorScheme: DEFAULT_COLOR_SCHEME,
    colorType: 'sequential' as ColorType,
    labelSize: 16,
    notSelectedOpacity: { features: 0.4, lines: 0.2 },
    scaleType: 'naturalbreaks' as ScaleType,
    shouldShowLabelsSelected: true,
    shouldShowScatterPaths: false,
    shouldZoomToFiltered: true,
    mapType: 'bubbles',
    view: 'bubble_and_map',
    setView: (newView) => {
        set({
            view: newView,
        });
    },
    setMapType: (newType) => {
        set({ mapType: newType });
    },
    hideLabelsSelected: () => {
        set({ shouldShowLabelsSelected: false, animationBuster: Date.now() });
    },
    hideScatterPaths: () => {
        set({ shouldShowScatterPaths: false, animationBuster: Date.now() });
    },
    setCircleSize: ({ key, value }) => {
        set({
            circleSize: { ...get().circleSize, [key]: value },
            animationBuster: Date.now(),
        });
    },
    setColorScheme: (colorScheme) => {
        set({ colorScheme, animationBuster: Date.now() });
    },
    setColorType: (colorType) => {
        set({ colorType, animationBuster: Date.now() });
    },
    setLabelSize: (size) => {
        set({ labelSize: size, animationBuster: Date.now() });
    },
    setNotSelectedOpacity: ({ key, value }) => {
        set({
            notSelectedOpacity: { ...get().notSelectedOpacity, [key]: value },
            animationBuster: Date.now(),
        });
    },
    setScaleType: (scaleType) => {
        set({ scaleType, animationBuster: Date.now() });
    },
    setShouldZoomToFiltered: (shouldZoomToFiltered) => {
        set({ shouldZoomToFiltered, animationBuster: Date.now() });
    },
    toggleShouldZoomToFiltered: () => {
        set({
            shouldZoomToFiltered: !get().shouldZoomToFiltered,
            animationBuster: Date.now(),
        });
    },
    showLabelsSelected: () => {
        set({ shouldShowLabelsSelected: true, animationBuster: Date.now() });
    },
    showScatterPaths: () => {
        set({ shouldShowScatterPaths: true, animationBuster: Date.now() });
    },
});

type ToolStore = CoreSlice & InteractionSlice & DataSlice & VisualizationSlice;

const getUrlSearch = () => {
    return window.location.search.slice(1);
};

function searchParamsStorage<S>() {
    return {
        getItem: (key: string) => {
            // Check URL first
            if (getUrlSearch()) {
                const result = qs.parse(getUrlSearch());

                return { state: result };
            }
            return null;
        },
        setItem: (key: string, newValue: { state: Partial<S> }): void => {
            const valueObj = newValue.state;
            const searchParams = qs.stringify(valueObj);

            window.history.replaceState(
                null,
                '',
                `?${searchParams.toString()}`
            );
        },
        removeItem: (key: string): void => {
            const searchParams = new URLSearchParams(getUrlSearch());
            const storedValue = JSON.parse(searchParams.get(key) || '{}');

            // Remove all related URL params
            for (const k of Object.keys(storedValue)) {
                searchParams.delete(k);
            }

            window.location.search = searchParams.toString();
        },
    };
}

export const useToolStore = create<ToolStore>()(
    persist(
        (...a) => ({
            ...createCoreSlice(...a),
            ...createInteractionSlice(...a),
            ...createDataSlice(...a),
            ...createVisualizationSlice(...a),
        }),
        {
            name: 'tool-store',
            storage: searchParamsStorage(),
            partialize: (state) => ({
                indicators: state.indicators,
                selectedFeatures: state.selectedFeatures,
                featureFilter: state.featureFilter,
                geoLevel: state.geoLevel,
                shouldShowScatterPaths: state.shouldShowScatterPaths,
                shouldZoomToFiltered: state.shouldZoomToFiltered,
                mapType: state.mapType,
                view: state.view,
            }),
        }
    )
);
